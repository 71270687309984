import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';

import { cycleGradient } from '../constants/keyframes';
import { fontStyles } from '../constants/styles';
import { breakpoints } from '../constants/mediaqueries';
import { defaultLangKey, locales } from '../data/metadata';
import { canUseDOM } from '../helpers/dom';
import { findValueForLocale, getCurrentLangKey } from '../helpers/i18n';
import { getNavOpen } from '../state/reducers';

import HelmetMichelberger from '../components/HelmetMichelberger';
import SplashScreen from '../components/SplashScreen';
import Layout from '../components/Layout';
import LocalisedLink from '../components/LocalisedLink';


const createGradient = (colors) => `linear-gradient(${colors.join(',')})`;
const introDuration = 5000;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: ${({ transition }) => ((transition && transition !== 'entered') ? 'hidden' : 'scroll')}
  -webkit-overflow-scrolling: touch;
`;

const HomePageGradient = styled.div`
  width: 100%;
  height: ${(props) => props.repeats * 300}%;
  background: ${(props) => createGradient(props.gradient)};
  background-size: 100% ${(props) => 100 / props.repeats}%;
  animation-name: ${({ introPlayed }) => (introPlayed ? 'none' : cycleGradient)};
  animation-duration: ${introDuration}ms;
  animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  animation-fill-mode: forwards;
`;

const Label = styled.div`
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  font-size: calc(5rem + 0.2vw);
  pointer-events: none;

  opacity: ${({ transition, navOpen }) => (transition === 'entered' && !navOpen ? 1 : 0)};
  transition: opacity 0.75s ease;

  line-height: 110%!important;

  text-align: center;

  ${fontStyles.display}
  letter-spacing: -0.05em;

  @media ${breakpoints.l} {
    opacity: ${({ transition }) => (transition === 'entered' ? 1 : 0)};
  }
`;

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.timer = null;

    /* eslint-disable no-underscore-dangle */
    let aboutPage;
    const langKey = getCurrentLangKey(locales, defaultLangKey, '');
    const { navigation } = this.props.data.cms;
    if (navigation.aboutPage) {
      aboutPage = {
        slug: findValueForLocale(navigation.aboutPage._allSlugLocales, langKey),
        name: findValueForLocale(navigation.aboutPage._allNameLocales, langKey),
        gradient: navigation.aboutPage.gradient,
      };
    }
    /* eslint-enable no-underscore-dangle */

    const { data: { allDatoCmsNavigation } } = this.props;
    const navigationData = allDatoCmsNavigation.edges[0].node;
    /*
     * `introPlayed` denotes whether or not the entire intro sequence should play or not.
     *  This should only play once per visit to the site so it's value is persisted in sessionStorage once this component's
     *  `componentWillUnmount` lifecycle method is called.
     */
    this.introPlayed = canUseDOM && (window.sessionStorage && !!window.sessionStorage.introPlayed);
    const {
      eventRoomsPage, familyPage, restaurantPage, roomsPage,
    } = navigationData;

    const navItems = [roomsPage, restaurantPage, eventRoomsPage, familyPage];

    if (aboutPage) navItems.splice(0, 0, aboutPage);

    this.sections = navItems.map((item) => ({ color: item.gradient[0].color.hex, label: item.name, url: `/${item.slug}` }));

    this.state = {
      currentLabel: this.sections[0].label,
      currentUrl: this.sections[0].url,
      transform: 0,
      opacity: 1,
      splashScreenVisible: !this.introPlayed,
    };

    this.scrollHandler = this.scrollHandler.bind(this);
  }

  componentWillUnmount() {
    window.sessionStorage.introPlayed = true;
    clearTimeout(this.timer);
  }

  resetScroll() {
    const { repeats } = this.props;
    const withinFirstSection = this.wrapper.scrollTop < this.wrapper.offsetHeight * this.sections.length;
    const withinLastSection = (this.wrapper.scrollTop * 2) > this.wrapper.offsetHeight * this.sections.length * (repeats - 1);

    if (withinFirstSection || withinLastSection) {
      const fullScrollHeight = this.panel.offsetHeight;
      const scrollPerRepeat = fullScrollHeight / repeats;

      this.wrapper.scrollTop = (this.wrapper.scrollTop % scrollPerRepeat) + (scrollPerRepeat * 2);
    }
  }

  scrollHandler(e) {
    const { repeats } = this.props;

    if (this.timer !== null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      this.resetScroll();
    }, 150);

    const relativeScrollPosition = e.target.scrollTop / ((this.panel.offsetHeight) / (this.sections.length * repeats));
    const currentSection = (Math.round(relativeScrollPosition) % this.sections.length);

    const relativePositionWithinSection = relativeScrollPosition - Math.round(relativeScrollPosition);
    const transform = `translateY(${relativePositionWithinSection * this.wrapper.offsetHeight * -0.8}px)`;
    const opacity = (0.5 - Math.abs(relativePositionWithinSection)) * 2;

    this.setState({
      currentLabel: this.sections[currentSection].label,
      currentUrl: this.sections[currentSection].url,
      transform,
      opacity,
    });
  }

  render() {
    const { data: { allDatoCmsHome }, location, repeats, navOpen } = this.props;
    const { currentLabel, currentUrl, opacity, transform, splashScreenVisible } = this.state;
    const { introPlayed } = this;

    const lastIndex = this.sections.length - 1;
    const gradient = [
      this.sections[lastIndex].color,
      ...this.sections.map((s) => s.color),
    ];

    const home = allDatoCmsHome.edges[0].node;

    return (
      <React.Fragment>
        <HelmetMichelberger seo={home.seoMetaTags} />
        <If condition={!introPlayed}>
          <SplashScreen onClick={() => this.setState({ splashScreenVisible: false })} splashScreenVisible={splashScreenVisible} />
        </If>
        <Transition enter in={!splashScreenVisible} mountOnEnter timeout={introDuration}>
          {(state) => (
            <Layout location={location}>
              <LocalisedLink className="gradient" displayBlock hasUnderline={false} to={currentUrl}>
                <Wrapper innerRef={(div) => { this.wrapper = div; }} onScroll={this.scrollHandler} transition={state}>
                  <HomePageGradient gradient={gradient} innerRef={(div) => { this.panel = div; }} introPlayed={introPlayed} repeats={repeats} />
                  <Label introPlayed={introPlayed} navOpen={navOpen} transition={state}>
                    <span style={{ transform, opacity }}>
                      {currentLabel}
                    </span>
                  </Label>
                </Wrapper>
              </LocalisedLink>
            </Layout>
          )}
        </Transition>
      </React.Fragment>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  repeats: PropTypes.number,
  navOpen: PropTypes.bool.isRequired,
};

HomePage.defaultProps = {
  repeats: 10,
};

const mapStateToProps = (state) => ({
  navOpen: getNavOpen(state),
});

export default connect(mapStateToProps)(HomePage);

export const query = graphql`
  query HomePage($locale: String!) {
    cms {
      navigation {
        aboutPage {
          _allSlugLocales {
            locale
            value
          }
          _allNameLocales {
            locale
            value
          }
          gradient {
            color {
              hex
            }
          }
        }
      }
    }
    allDatoCmsNavigation(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          roomsPage {
            slug
            name
            gradient {
              color {
                hex
              }
            }
          }
          restaurantPage {
            slug
            name
            gradient {
              color {
                hex
              }
            }
          }
          eventRoomsPage {
            slug
            name
            gradient {
              color {
                hex
              }
            }
          }
          familyPage {
            slug
            name
            gradient {
              color {
                hex
              }
            }
          }
        }
      }
    }
    allDatoCmsHome(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
  }
`;
