import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Transition from 'react-transition-group/Transition';

import { fadeIn } from '../constants/keyframes';
import { fontStyles } from '../constants/styles';
import { typeSizes } from '../constants/typography';
import isTouch from '../helpers/istouch';


const durationFade = 2500;

const IntroContainer = styled.div`
  background-color: white;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 3;
  animation-name: ${({ transition }) => (transition !== 'entered' ? fadeIn : 'none')};
  animation-duration: ${durationFade}ms;
  animation-timing-function: ease-in;
  animation-direction: reverse;
  cursor: none;

  h2 {
    font-size: ${typeSizes.s};
    ${fontStyles.uppercase}
  }
`;


export default class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.setCursorPosition = this.setCursorPosition.bind(this);
    this.cursorRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.setCursorPosition);
  }

  setCursorPosition({ clientX, clientY }) {
    const { innerWidth, innerHeight } = window;
    const node = this.cursorRef.current;

    if (!node || !this.props.splashScreenVisible) {
      window.removeEventListener('mousemove', this.setCursorPosition);
      return;
    }

    const [x, y] = [(clientX - (innerWidth / 2)), (clientY - (innerHeight / 2))];
    node.style.transform = `translate(${x}px, ${y}px)`;
  }

  render() {
    const { splashScreenVisible, onClick } = this.props;
    const imgStyles = {
      position: 'absolute',
      height: '100px',
      width: '100px',
      backgroundImage: 'url("/Michelberger-Prism.png")',
      backgroundPosition: 'auto auto',
      backgroundSize: '100%',
      display: isTouch ? 'none' : 'block',
    };

    return (
      <Transition exit in={splashScreenVisible} timeout={durationFade} unmountOnExit>
        {(state) => (
          <IntroContainer onClick={onClick} transition={state}>
            <h2>Michelberger</h2>
            <div ref={this.cursorRef} style={imgStyles} />
          </IntroContainer>
        )}
      </Transition>
    );
  }
}

SplashScreen.propTypes = {
  splashScreenVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
